import { ContentContext } from '@components/layouts'
import React, { useContext, useEffect, useState } from 'react'
import { FaGlobeAfrica } from '@react-icons/all-files/fa/FaGlobeAfrica'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import toast, { Toaster } from 'react-hot-toast'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { article_GET } from '../../services/api-Calls/article_GET'
import { randomArticles_GET } from '../../services/api-Calls/randomArticles_GET'
import RandArticleCard from '@components/randArticleCard'
import ReactMarkdown from 'react-markdown'
import ReactDom from 'react-dom'
import RandArticlesCard from '@components/randArticlesCard'
import BlogCommentPostCard from '@components/blogCommentPostCard'
import BlogCommentsCard from '@components/blogCommentsCard'
import { blogComments_GET } from '../../services/api-Calls/blogComments_GET'
import BlogCommentCard from '@components/blogCommentCard'
import Nav from '@components/nav'
import BlogArticles from '@components/blogArticles'
import BlogSidebar from '@components/blogSidebar'
import BlogPostCard from '@components/blogPostCard'
import { searchResults_GET } from '../../services/api-Calls/searchResults_GET'

interface Props {
  query: string
}

const SearchContent: React.FC<Props> = ({ query }) => {
  const { lang, translate, setLanguage } = useContext(ContentContext)
  const [activePage, setActivePage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [isNoResults, setIsNoResults] = useState<any>()
  const [searchResults, setSearchResults] = useState<Array<JSX.Element>>([])

  const sideData = useStaticQuery(graphql`
    query NanodevBlogArticlesData {
      popularPosts: allStrapiNanodevBlog(
        filter: { post_likes_amt: { gte: 5 } }
      ) {
        nodes {
          post_title
          post_at
          post_id
          post_link
          post_image {
            formats {
              small {
                url
              }
            }
          }
          localizations {
            data {
              attributes {
                locale
                post_title
              }
              id
            }
          }
        }
      }
      categories: allStrapiNanodevBlog {
        nodes {
          post_category
        }
      }
      previousDates: allStrapiNanodevBlog {
        nodes {
          post_at
        }
      }
    }
  `)

  const getPaginatedArr = (arr: JSX.Element[], chunkSize: number) => {
    let res = []
    var x = 0
    while (x < arr.length) {
      if (x === 0) {
        const chunk = arr.slice(x, x + (chunkSize - 2))
        // chunk.unshift(<BlogLatestPostCard/>)
        x = x - 2
        // console.log('FIRST RUN', chunk)
        res.push(chunk)
      } else {
        const chunk = arr.slice(x, x + chunkSize)
        // console.log(x, ' RUN', chunk)
        res.push(chunk)
      }
      x += chunkSize
    }
    return res
  }

  const populateSearchResults = async () => {
    try {
      await searchResults_GET(lang, query).then(blogData => {
        let tempVar: JSX.Element[] = []
        if (blogData.data.length >= 1) {
          blogData.data.forEach((element: any, x: number) => {
            tempVar[x] = (
              <BlogPostCard
                postImage={
                  element.attributes.post_image.data.attributes.formats.large
                    .url
                }
                category={element.attributes.post_category}
                title={element.attributes.post_title}
                description={element.attributes.post_description}
                at={element.attributes.post_at}
                author={element.attributes.post_author}
                postID={element.attributes.post_id}
              />
            )
          })
          setSearchResults(tempVar)
          setIsNoResults(false)
          setIsLoading(false)
        } else {
          setIsNoResults(true)
          setIsLoading(false)
        }
      })
    } catch (err) {}
  }

  var paginatedArticles = getPaginatedArr(searchResults, 6)

  useEffect(() => {
    setSearchResults([])
    populateSearchResults()
  }, [lang, query])

  // console.log('RESULTS STATE :', isNoResults)

  return (
    <>
      <div className="content-wrapper mt-12">
        <section className="wrapper bg-white pt-5">
          <div className="container py-10 py-md-10">
            <div className="row gx-lg-8 gx-xl-12">
              <div className="col-lg-8 d-flex flex-column">
                <div className="col-12 mb-2">
                  {isNoResults == false && isLoading == false ? (
                    <div className="p-1">
                      {isLoading == false ? (
                        <BlogArticles
                          articlesCards={paginatedArticles[activePage - 1]}
                          pageNum={activePage}
                        />
                      ) : (
                        <div className="d-flex justify-content-center p-10 text-primary">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex align-items-center flex-column col-12 justify-content-center p-10 text-primary">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-article-off"
                          width="100"
                          height="100"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M8 4h11a2 2 0 0 1 2 2v11m-1.172 2.821a1.993 1.993 0 0 1 -.828 .179h-14a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 1.156 -1.814"></path>
                          <path d="M7 8h1m4 0h5"></path>
                          <path d="M7 12h5m4 0h1"></path>
                          <path d="M7 16h9"></path>
                          <path d="M3 3l18 18"></path>
                        </svg>
                      </div>
                      <div>
                        <p className="text-gray-600 dark:text-gray-300">
                          {translate('blog_no_article')}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <nav className="d-flex" aria-label="pagination">
                    <ul className="pagination">
                      <li
                        className={
                          'page-item page-link ' +
                          (activePage == 1 ? 'disabled' : '')
                        }
                        onClick={
                          activePage >= 2
                            ? () => {
                                setActivePage(activePage - 1)
                              }
                            : () => {}
                        }
                      >
                        <span aria-hidden="true">
                          <i
                            className={
                              'uil uil-arrow-left ' +
                              (activePage == 1 ? 'text-secondary' : '')
                            }
                          ></i>
                        </span>
                      </li>

                      {paginatedArticles.map((article, index) => (
                        <li
                          key={index}
                          className={
                            'page-item page-link ' +
                            (activePage === index + 1 ? 'active' : '')
                          }
                          onClick={() => setActivePage(index + 1)}
                        >
                          {index + 1}
                        </li>
                      ))}
                      <a>
                        <li
                          className={
                            'page-item page-link ' +
                            (activePage == paginatedArticles.length
                              ? 'disabled'
                              : '')
                          }
                          onClick={
                            activePage < paginatedArticles.length
                              ? () => {
                                  setActivePage(activePage + 1)
                                }
                              : () => {}
                          }
                        >
                          <span aria-hidden="true">
                            <i
                              className={
                                'uil uil-arrow-right ' +
                                (activePage == paginatedArticles.length
                                  ? 'text-secondary'
                                  : '')
                              }
                            ></i>
                          </span>
                        </li>
                      </a>
                    </ul>
                  </nav>
                </div>
              </div>
              <BlogSidebar
                categoriesData={sideData.categories.nodes}
                popularArticlesData={sideData.popularPosts.nodes}
                previousDatesData={sideData.previousDates.nodes}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SearchContent
