const searchResults_GET = async (lang: string, query: string) => {
  let response = await fetch(
    `https://strapi-nanodev.up.railway.app/api/nanodev-blogs?filters[post_title][$containsi]=${query}&populate=*&locale=${lang}`
  )
  if (response.status == 200) {
    let data = await response.json()
    return data
  } else {
    throw new Error(response.statusText)
  }
}

export { searchResults_GET }
